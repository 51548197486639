<div class="">
  <div class="text-center">
    <img class="img-fluid mt-5" src="assets/images/undraw_Order_confirmed_re_g0if.svg" alt="Paypal connect success" width="30%" >
    <div class="text-center mt-5">
      <h4>{{ 'general.thank' | translate }}</h4>
      <h5>{{ 'general.receiptSent' | translate }}</h5>
    </div>
  </div>
</div>

