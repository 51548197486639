<nb-layout>
  <nb-layout-column>
    <div class="content" *ngIf="proposal && customization" >
      <div class="p-5" id="pdfContent" #pdf>
        <div class="row" >
          <div class="col-6">
            <h5 *ngIf="company">{{company.name}}</h5>
          </div>
          <div class="col-6 text-end">
            <h5 *ngIf="customization.estimateTitle">{{customization.estimateTitle}}</h5>
            <h5 *ngIf="!customization.estimateTitle">{{'general.estimate' | translate}}</h5>
            <small *ngIf="!customization.estimateSubtitle">{{customization.estimateSubtitle}}</small>

            <div class="mt-2"><b>{{'general.reference' | translate }}: </b> {{proposal.reference}}</div>
            <div class="mt-2"> <b>{{'general.date' | translate }}:</b> {{proposal.proposalDate | date: 'MMM d, y, HH:mm'}}</div>
            <div class="mt-2" *ngIf="proposal.proposalNumber"><b>{{'general.number' | translate }}:</b> {{proposal.proposalNumber}}</div>
            <div class="mt-2"><b>{{'general.dueDate' | translate }}: </b> {{proposal.issueDate | date: 'MMM d, y, HH:mm'}}</div>
          </div>
        </div>

        <div class="row" >
          <div class="col-6">
            <div class=""><b>{{'general.sentTo' | translate }}</b></div>
            <div>
              {{proposal.customer.name}}
            </div>
            <div>
              {{proposal.customer.phone}}
            </div>
            <div *ngIf="proposal.customer.billingAddress">
              <address>
                <div>{{proposal.customer.billingAddress.addressLine1}}</div>
                <div>{{proposal.customer.billingAddress.addressLine2}} </div>
                <div>{{proposal.customer.billingAddress.zipCode}} {{proposal.customer.billingAddress.city}} - {{proposal.customer.billingAddress.country}}</div>
              </address>
            </div>
          </div>
          <div class="col-6 text-end" *ngIf="company">
            <div class="mt-3"><b>{{'general.from' | translate }}</b></div>
            <div>
              {{company.name}}
             </div>
            <div>
              {{company.phone}}
            </div>
            <div *ngIf="company.billingAddress">
              <address>
                <div>{{company.billingAddress.addressLine1}} </div>
                <div>{{company.billingAddress.addressLine2}}</div>
                <div>{{company.billingAddress.zipCode}} {{company.billingAddress.city}} - {{company.billingAddress.country}}</div>
              </address>
            </div>
          </div>
        </div>

        <div class="row  mt-5">
          <div class="col-6">

          </div>
          <div class="col-6 text-end">
            <div class="">
              <h5>{{'general.amountDue' | translate }}</h5>
              <h4 class="text-primary"> <span *ngIf="proposal.currency">{{proposal.currency.symbol}}</span> {{proposal.totalAmount}} </h4>
            </div>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12">
            <h6>{{ 'estimate.detail.productsAndServices' | translate }}</h6>
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">
                      <span  *ngIf="customization.items">{{customization.items}}</span>
                      <span  *ngIf="!customization.items">{{ 'invoice.detail.items' | translate }}</span>
                    </th>
                    <th scope="col" *ngIf="!customization.hideCategory">{{ 'invoice.detail.category' | translate }}</th>
                    <th scope="col" *ngIf="!customization.hideQuantity">{{ 'general.quantity' | translate }}</th>
                    <th scope="col" *ngIf="!customization.hidePrice">
                      <span  *ngIf="customization.price">{{customization.price}}</span>
                      <span  *ngIf="!customization.price"> {{ 'invoice.detail.price' | translate }}</span>
                    </th>
                    <th scope="col" *ngIf="!customization.hideTax">{{ 'invoice.detail.tax' | translate }}</th>
                    <th scope="col"  *ngIf="!customization.hideDiscount">{{ 'invoice.detail.discount' | translate }} (%)</th>
                    <th scope="col" >
                      <span  *ngIf="customization.amount">{{customization.amount}}</span>
                      <span  *ngIf="!customization.amount"> {{ 'invoice.detail.totalPrice' | translate }}</span>
                    </th>
                  </tr>
                </thead>
                <tbody *ngIf="proposal.proposalLineItems && proposal.proposalLineItems.length > 0">
                  <ng-container *ngFor="let item of proposal.proposalLineItems">
                    <tr>
                      <td>
                        <b>
                          <span *ngIf="item.product != null && item.category === 'product'" >{{item.product.name}} </span>
                          <span *ngIf="item.service != null && item.category === 'service'" >{{item.service.name}}</span>
                        </b>
                        <span *ngIf="!customization.hideDescription"><br />{{item.description}}</span>
                      </td>
                      <td *ngIf="!customization.hideCategory" >{{item.category}}</td>
                      <td *ngIf="!customization.hideQuantity" >{{item.quantity}}</td>
                      <td  *ngIf="!customization.hidePrice"><span *ngIf="proposal.currency">{{proposal.currency.symbol}}</span>{{item.price}}</td>
                      <td *ngIf="item.product != null && item.category === 'product' && !customization.hideTax" >
                        <span *ngIf="item.product.tax != null">{{item.product.tax.name}} ({{item.product.tax.rate}}%)</span>
                      </td>
                      <td *ngIf="item.service != null && item.category === 'service' && !customization.hideTax" >
                        <span *ngIf="item.service.tax != null">{{item.service.tax.name}} ({{item.service.tax.rate}}%)</span>
                      </td>
                      <td  *ngIf="!customization.hideDiscount">{{item.discount}}%</td>
                      <td><span *ngIf="proposal.currency">{{proposal.currency.symbol}}</span>{{item.totalPrice}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-6"></div>
          <div class="col-6">
            <div class="row">
              <div class="col-4">
              </div>
              <div class="col-8 bg-light">
                <table class="table table-borderless">
                  <tbody>
                    <tr>
                      <td>{{ 'general.subTotal' | translate }}: </td>
                      <td><span *ngIf="proposal.currency">{{proposal.currency.symbol}}</span>{{proposal.subTotalPrice}}</td>
                    </tr>
                    <tr *ngIf="!customization.hideDiscount">
                      <td>{{ 'general.discount' | translate }}:</td>
                      <td><span *ngIf="proposal.currency">{{proposal.currency.symbol}}</span>{{proposal.totalDiscount}}</td>
                    </tr>
                    <tr *ngIf="!customization.hideTax">
                      <td>{{ 'general.tax' | translate }}:</td>
                      <td><span *ngIf="proposal.currency">{{proposal.currency.symbol}}</span>{{proposal.totalTax}}</td>
                    </tr>
                    <ng-container *ngIf="proposal.totalCreditNote">
                      <tr>
                        <td>{{ 'general.creditNote' | translate }}: </td>
                        <td><span *ngIf="proposal.currency">{{proposal.currency.symbol}}</span>{{proposal.totalCreditNote}}</td>
                      </tr>
                    </ng-container>
                    <tr>
                      <td><b>{{ 'general.totalPrice' | translate }}:</b></td>
                      <td><b><span *ngIf="proposal.currency">{{proposal.currency.symbol}}</span>{{proposal.totalAmount}}</b></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12">
            <div for="description">{{ 'general.noteAndTerms' | translate }}</div>
            <div *ngIf="customization.estimateNotes && !proposal.description">{{customization.estimateNotes}}</div>
            <div  *ngIf="customization.estimateNotes || proposal.description">{{proposal.description}}</div>
          </div>
        </div>

        <div class="row mt-5">
          <hr>
          <div class="col-12">
            <div class="text-center" *ngIf="customization.estimateFooter">{{customization.estimateFooter}}</div>
          </div>
        </div>
      </div>
    </div>

  </nb-layout-column>
</nb-layout>
