
<nb-layout>
  <nb-layout-column>
    <div class="content" *ngIf="invoice; else noContent">
      <div class="pb-2">
        <a class="me-2" (click)="downloadPdf()" nbButton status="control" [nbSpinner]="downloading"
          size="small">
          <nb-icon icon="download-outline"></nb-icon>
          {{ 'general.download' | translate }}
        </a>
        <a nbButton status="control" size="small" (click)="print()" [nbSpinner]="printing">
          <nb-icon icon="printer-outline"></nb-icon>
          {{ 'general.print' | translate }}
        </a>
      </div>
      <div>
        <nb-card accent="primary" [nbSpinner]="loading">
          <nb-card-header>
            <div>
              <span>
                {{ 'general.reference' | translate }}: <b>{{invoice.reference}}</b>
              </span>
              <span class="float-end">{{ 'invoice.index.dueDate' | translate }}: <b>{{invoice.issueDate | date: 'MMM d, y, HH:mm'}}</b></span>
            </div>
          </nb-card-header>
          <nb-card-body>
            <h6>{{ 'general.amountDue' | translate }}</h6>
            <div>
              <h2>
                <span *ngIf="invoice.currency">{{invoice.currency.symbol}}</span> {{invoice.totalAmount}}
              </h2>
            </div>
            <div class="row mt-4">
              <div class="col-10" *ngIf="hasStripeConnected">
                <div class="d-flex flex-column bd-highlight">
                  <div class="p-2 bd-highlight">
                    <button (click)="stripeCheckout()" nbButton status="primary" size="medium" class="me-2"
                      [nbSpinner]="checkoutLoading">
                      <nb-icon icon="credit-card-outline"></nb-icon>
                      {{ 'invoice.detail.ccp' | translate }}
                    </button>
                    <span class="icon-size mt-2 align-middle">
                      <i class="fab fa-cc-mastercard"></i>
                    </span>
                    <span class="icon-size ms-2 align-middle">
                      <i class="fab fa-cc-visa"></i>
                    </span>
                    <span class="icon-size ms-2 align-middle">
                      <i class="fab fa-cc-amex"></i>
                    </span>
                    <span class="ms-2 align-middle">
                      {{ 'general.andMore' | translate }}...
                    </span>
                  </div>
                  <div class="p-1 bd-highlight">
                    <div #paypal></div>
                  </div>
                </div>
              </div>
              <div class="col-2 align-text-bottom">

              </div>
            </div>
          </nb-card-body>
        </nb-card>
      </div>
      <div class="p-5" id="pdfContent" >
        <div id="pdfSpace"></div>
      </div>

    </div>

    <ng-template #noContent>
      <div class="text-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </ng-template>

  </nb-layout-column>
</nb-layout>
